import Button from "components/Button";
import PageHeader from "components/PageHeader";
// import PageHeader from "components/PageHeader";
import RenderData from "components/RenderData";
import useQuery from "components/useQuery";
import { Template } from "layouts/Template";
import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery } from "redux/features/diagnostiqueApi";
import { getCurrentEleve } from "redux/features/eleveSlice";
import { ChevronRight, User } from "tabler-icons-react";
import {
  calculePourcentageReussiteParNiveau,
  compareNote,
} from "utils/comparentText";
// import Divider from "../matiere/components/Divider";

function AnalysePage() {
  const params = useParams();
  const navigation = useNavigate();

  const searchParams = useQuery();

  const studentDemo = useSelector(getCurrentEleve);

  //   const [activeLevel, setActiveLevel] = useState(1);

  // const { data: eleve } = useGetEleveQuery(params?.eleveId);

  const { data, isLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery(
      parseInt(params?.id)
    );

  useLayoutEffect(() => {
    navigation("?level=1", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log({ data });
  // }, []);

  return (
    <Template
      header={
        <>
          <PageHeader />
          <div className="flex justify-between items-center px-2 py-4">
            <div className="flex gap-2 items-center">
              {/* <div
              className="cursor-pointer flex items-center justify-center px-1"
              onClick={handleGoBack}
            >
              <ArrowLeft />
            </div> */}
              <div className="h-10 w-10 bg-primary rounded-full flex justify-center items-center">
                <User color="#fff" size={18} />
              </div>
              {/* <img
                // src="../../../assets/icons/faceBlack.png"
                src="../assets/icons/faceBlack.png"
                alt="face"
                className=""
              /> */}
              <div className="text-md">
                <p className="-mb-1 font-bold">
                  {/* {eleve?.firstName} {eleve?.lastName} */}
                  {studentDemo?.fullName}
                </p>
                {/* <p className="text-xs">classe: {eleve?.classe?.name}</p> */}
                <p className="text-xs">classe: {studentDemo?.classe}</p>
              </div>
            </div>
            <h2
              className="text-center px-3 py-1 rounded-xl text-xs"
              style={{ backgroundColor: "rgba(0,0,0,.1)" }}
            >
              Diagnostic {params?.name}
            </h2>
          </div>
        </>
      }
      headerStyle={{ height: 120, backgroundColor: "rgba(0,0,0,0)" }}
      style={{
        backgroundColor: "rgba(0,0,0,0.05)",
      }}
      footer={
        <div
          style={{
            display: "flex",
            gap: 10,
            padding: "0px 20px",
            marginTop: 5,
          }}
        >
          <Button
            style={{
              backgroundColor: "#003880",
              flex: 1,
              height: 40,
              borderRadius: 20,
            }}
            radius={"sm"}
            onClick={() => {
              navigation(-1);
              navigation(-1);
            }}
          >
            Quitter
          </Button>
        </div>
      }
    >
      <div
        className="px-2 py-2 flex items-center justify-between pt-4"
        style={{ backgroundColor: "#ffffff" }}
      >
        {/* <div className="rounded-full w-16 h-16 bg-primary flex justify-center items-center">
          <div className="rounded-full w-14 h-14 bg-secondary flex justify-center items-center text-white font-bold text-lg">
            30 %
          </div>
        </div> */}
        <div className="relative justify-center flex-1 items-center gap-3 flex text-center text-[#17377D]">
          <span className="font-bold text-[20px] ">
            Résultat du Diagnostic :
          </span>
          {/* <span className="font-bold text-xl">
            {calculePourcentageReussiteDiagnostique(
              calculePourcentageReussiteParNiveau(data?.data?.notes["1"]),
              calculePourcentageReussiteParNiveau(data?.data?.notes["2"]),
              calculePourcentageReussiteParNiveau(data?.data?.notes["3"])
            )}
            %
          </span> */}
        </div>
        {/* <Link
          to={"../comparaison/" + data?.data?.diagnostic}
          className="flex items-center gap-2"
        >
          <p className="font-bold">Comparaison</p>
          <ArrowsDiff className="text-secondary" />
        </Link> */}
      </div>

      <div
        className="sticky top-0 h-12 flex justify-between flex-nowrap items-end z-10 "
        style={{ backgroundColor: "#ffffff" }}
      >
        {[
          {
            id: 1,
            label: "Lacunes",
            note: calculePourcentageReussiteParNiveau(data?.data?.notes["1"]),
            color: "red",
          },
          {
            id: 2,
            label: "A ameliorer",
            note: calculePourcentageReussiteParNiveau(data?.data?.notes["2"]),
            color: "orange",
          },
          {
            id: 3,
            label: "Acquis",
            note: calculePourcentageReussiteParNiveau(data?.data?.notes["3"]),
            color: "green",
          },
          {
            id: 0,
            label: "Recap",
            note: calculePourcentageReussiteParNiveau(data?.data?.notes["1"]),
            color: "#000",
          },
        ].map((niveau) => (
          <div
            className={`border-b-[3px] w-full justify-center gap-2 items-center pb-2 font-bold ${
              parseInt(searchParams.get("level")) === niveau?.id
                ? "border-primary"
                : "border-gray"
            } cursor-pointer flex text-xs z-10`}
            style={{
              color: niveau?.color,
              borderColor:
                parseInt(searchParams.get("level")) === niveau?.id
                  ? niveau.color
                  : "rgba(0,0,0,0)",
            }}
            key={niveau.id}
            onClick={() =>
              navigation("?level=" + niveau?.id, { replace: true })
            }
          >
            {niveau.label === "Recap" ? null : (
              <div
                className="w-[10px] h-[10px] rounded-full"
                style={{ backgroundColor: niveau.color }}
              ></div>
            )}
            <p className="text-md">{niveau.label}</p>
            {/* <span className="bg-gray px-2 rounded-2xl font-bold">
              {niveau?.note ?? 0} %
            </span> */}
          </div>
        ))}
      </div>

      <div className="p-2">
        {/* <Divider /> */}

        <RenderData
          isLoading={isLoading}
          data={
            data?.data
              ? // ? searchParams.get("level") === "0"
                Object.values(data?.data?.notes)
              : // : data?.data?.notes[searchParams.get("level") ?? "1"] ?? []
                []
          }
          render={(item, index) => {
            // const analyse = compareNote(item?.bareme, item?.note);
            return (
              <div key={index} className="mb-5">
                {item?.filter((el) => {
                  const _analyse = compareNote(el?.bareme, el?.note);
                  return (
                    _analyse?.identifiant.toString() ===
                    searchParams.get("level")
                  );
                })?.length <= 0 && searchParams.get("level") !== "0" ? null : (
                  <>
                    <div className="flex items-center gap-3 text-primary p-2 font-bold">
                      <p>Niveau {index + 1}</p>
                      {searchParams.get("level") !== "0" ? null : (
                        <p>({calculePourcentageReussiteParNiveau(item)}%)</p>
                      )}
                    </div>
                    <RenderData
                      data={item}
                      render={(_item, _index) => {
                        const _analyse = compareNote(
                          _item?.bareme,
                          _item?.note
                        );
                        return (_analyse?.identifiant).toString() ===
                          searchParams.get("level") ||
                          searchParams.get("level") === "0" ? (
                          <div
                            key={_index}
                            className="mb-7"
                            onClick={() =>
                              navigation("../exercice-detail", {
                                state: { item: _item, analyse: _analyse },
                              })
                            }
                          >
                            {/* <div className="flex gap-2 items-center">
                            <span className="w-2 h-5 bg-primary rounded-full"></span>
                            <p className="font-bold text-md">{_item?.titre}</p>
                          </div> */}
                            <div
                              className="ml-3 p-4 bg-white rounded-lg mt-0 relative text-sm"
                              style={{
                                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.08)",
                              }}
                            >
                              <div className="flex gap-3">
                                <div
                                  className="w-4 h-4 rounded-full"
                                  style={{ backgroundColor: _analyse?.color }}
                                ></div>
                                <div className="">
                                  <p className="font-bold">
                                    {_analyse?.interpretation.toUpperCase()}
                                  </p>
                                  <div className="flex gap-2 items-center">
                                    <p className="font-bold text-md">
                                      {_item?.titre}
                                    </p>
                                  </div>
                                  <div className="flex gap-2 text-primary cursor-pointer mt-2">
                                    <span className="font-bold">
                                      voir plus{" "}
                                    </span>
                                    <ChevronRight />
                                  </div>
                                  {/* <span className="font-bold">
                                  {_analyse.message.toUpperCase()}
                                </span> */}
                                  {/* <br /> */}
                                  {/* Sur cette partie, l'élève a obtenu{" "}
                                <span className="font-bold">
                                  {_item?.note}
                                </span>{" "}
                                sur{" "}
                                <span className="font-bold">{_item?.bareme}</span> */}
                                  <span className="absolute right-2 -bottom-4 bg-white rounded-full px-2 h-8 flex items-center justify-center">
                                    {_analyse?.icon}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      }}
                    />

                    <div className="w-full h-[1px] bg-gray"></div>
                  </>
                )}
              </div>
            );
          }}
        />
      </div>
    </Template>
  );
}

export default AnalysePage;
