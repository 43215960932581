import { useState } from "react";
import Divider from "../components/Divider";
import MatiereCard from "../components/MatiereCard";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useGetMatieresWithDiagnosticQuery } from "redux/features/matiereApi";
import { useGetDiagnostiqueTentativeByEleveQuery } from "redux/features/diagnostiqueApi";
import Tentative from "pages/home/components/tentative";
import useGetStudent from "hooks/useGetStudent";
import { useSelector } from "react-redux";
import { getCurrentEleve, getTentatives } from "redux/features/eleveSlice";
import { progressImage } from "utils/images";
import { useDispatch } from "react-redux";
import {
  getParentForm,
  setIncreaseCredit,
  setParentForm,
} from "redux/features/parentSlice";
import { useUpdateParentMutation } from "redux/features/parentApi";
import { clearNote, setNewDiagnostic } from "redux/features/diagnostiqueSlice";
import useGetUserInfo from "hooks/useGetUserInfo";
import { toast } from "react-toastify";
import Modal from "components/modal";
import { ArrowRight, Loader, Plus } from "tabler-icons-react";
import useGetAssurance from "hooks/useGetAssurance";

function DiagnostiqueHistoriquePage() {
  const navigation = useNavigate();

  const { userId } = useGetUserInfo();

  const params = useParams();

  const dispatch = useDispatch();

  const formParent = useSelector(getParentForm);

  const [show, setShow] = useState(false);
  const [newCreditLoading] = useState(false);
  const [diagnostique, setDiagnostique] = useState(null);

  const student = useGetStudent();

  const app = useGetAssurance();

  const studentDemo = useSelector(getCurrentEleve);

  const [UPDATEPARENTCREDIT, { isLoading: updateLoading }] =
    useUpdateParentMutation();

  const {
    data: matierDiagnosticData,
    isLoading,
    isError,
  } = useGetMatieresWithDiagnosticQuery({
    classeId: studentDemo?.classeId,
  });

  // Filter out Demo Diagnostics from Normal
  const filteredMatierDiagnosticData =
    formParent?.type === "CONFIRMED"
      ? matierDiagnosticData?.data?.filter(
          (item) => item?.type_diagnostic === "NORMAL"
        )
      : matierDiagnosticData?.data?.filter(
          (item) => item?.type_diagnostic === "DEMO"
        );

  const tentativeDemos = useSelector(getTentatives)?.filter(
    (tentative) => tentative?.eleveId === studentDemo?.id
  );

  const { data: diagnostiqueData } = useGetDiagnostiqueTentativeByEleveQuery(
    params?.eleveId
  );

  /**
   * Recharger du credit
   */
  const handleSetCredt = async () => {
    navigation("/payment");
  };

  /**
   *
   */
  const handleStarted = async () => {
    try {
      dispatch(clearNote());
      dispatch(setIncreaseCredit());

      const resStatus = await UPDATEPARENTCREDIT({
        parentId: formParent?.id,
        credits: formParent?.credits - 25,
      });

      if (resStatus?.data) {
        // la redirection
        dispatch(
          setParentForm({
            ...formParent,
            credits: resStatus?.data?.data?.credits,
          })
        );

        navigation({
          pathname: `/${
            student?.id
          }/${diagnostique?.matiereId?.name?.toLowerCase()}/${
            diagnostique?.matiereId?.name?.toLowerCase() === "lecture"
              ? "lecture"
              : "math"
          }/${diagnostique?.diagnosticId}`,

          state: {
            eleveId: student?.id,
            randomImage: progressImage[parseInt(Math.random() * 6)],
          },
          search: `?${createSearchParams({
            niveau: 0,
            epreuve: 0,
          })}`,
        });
      } else {
        toast.error("Une erreur c'est produite");
      }
    } catch (error) {
      toast.error("Une erreur s'est produite");
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="h-[100%] py-10">Chargement en cours...</div>
      ) : (
        <>
          <div className="p-2">
            <span className="font-bold text-md">
              Sélectionnez une matière pour commencer un diagnostic.
            </span>
          </div>
          <div className="" style={{ display: "flex", gap: 20, padding: 10 }}>
            {isError ? (
              "Erreur sur les matieres"
            ) : filteredMatierDiagnosticData &&
              filteredMatierDiagnosticData?.length === 0 ? (
              <div className="p-4 bg-white rounded-lg">
                Pas de diagnostic disponible
              </div>
            ) : (
              filteredMatierDiagnosticData?.map((item, index) => (
                <MatiereCard
                  key={index}
                  name={item?.matiereId?.name}
                  onClick={() => {
                    dispatch(
                      setNewDiagnostic({
                        diagnosticId: diagnostique?.diagnosticId,
                        studentId: params?.eleveId,
                        coachId: userId,
                      })
                    );
                    if (
                      app === "Senassurance" &&
                      formParent?.type === "CONFIRMED"
                    ) {
                      navigation({
                        pathname: `/${
                          student?.id
                        }/${item?.matiereId?.name?.toLowerCase()}/${
                          item?.matiereId?.name?.toLowerCase() === "lecture"
                            ? "lecture"
                            : "math"
                        }/${item?.diagnosticId}`,
                        state: {
                          eleveId: student?.id,
                          randomImage:
                            progressImage[parseInt(Math.random() * 6)],
                        },
                        search: `?${createSearchParams({
                          niveau: 0,
                          epreuve: 0,
                        })}`,
                      });
                    } else {
                      setDiagnostique(item);
                      setShow(true);
                    }
                  }}
                  img={item?.matiereId?.name === "LECTURE" ? "book" : "math"}
                />
              ))
            )}
          </div>
        </>
      )}

      <Divider />

      {/* Liste des tentatives */}
      <Tentative
        eleve={student}
        studentDemo={studentDemo}
        offlineData={tentativeDemos ?? null}
        // isLoading={diagnosticLoadings}
        data={diagnostiqueData ? diagnostiqueData?.data : []}
        // onClick={(history) =>
        //   navigation("historiques/" + history?.id, {
        //     state: {
        //       eleveId: student?.id,
        //       seuil: "70",
        //       name: history?.diagnostic.split(" ").at(-1),
        //     },
        //   })
        // }
        onClick={(history) =>
          navigation("../lecture/analyse/" + history?.id, {
            state: {
              eleveId: student?.id,
              seuil: "70",
              // name: history?.diagnostic.split(" ").at(-1),
            },
          })
        }
      />

      {/* Modal */}
      <Modal
        opened={show}
        onClosed={() => (newCreditLoading ? null : setShow(false))}
      >
        <h1 className="font-bold text-xl text-center">
          Commencer le diagnostic
        </h1>
        {formParent?.credits <= 0 ? (
          <h1 className="font-[500] text-md text-center my-5">
            Il vous reste{" "}
            <span className="text-2xl font-bold text-[green]">
              {formParent?.credits} points
            </span>{" "}
            <br />
            Vous ne pouvez plus continuer
          </h1>
        ) : (
          // Actuellement, vous disposez de 175 points. À chaque démarrage d'un diagnostic,
          // 25 points seront déduits. Si toutefois il vous reste 0 point,
          // il faudra envisager de créer un compte ou bien de contacter Genimi.
          <h1 className="font-[500] text-md text-center my-5">
            {formParent?.credits < 100
              ? "Il vous reste "
              : "Actuellement, vous disposez de"}{" "}
            <span className="text-2xl font-bold text-[green]">
              {formParent?.credits} points.
            </span>{" "}
            À chaque démarrage d'un diagnostic,{" "}
            <span className="text-lg font-bold text-[red]">{25} points</span>{" "}
            points seront déduits. Si toutefois il vous reste 0 point, il faudra
            envisager de contacter Genimi.
          </h1>
        )}

        {formParent?.credits <= 0 ? (
          <div className="flex items-center gap-5">
            <button
              onClick={handleSetCredt}
              disabled={updateLoading}
              className="animate-bounce flex-1 default:opacity-70 px-2 hover:animate-none bg-primary h-[40px] rounded-full text-[white] mt-6 flex items-center justify-center gap-5"
            >
              {updateLoading ? <Loader className="animate-spin" /> : <Plus />}
              {updateLoading ? "Recharge en cours..." : "Recharger du credit"}
            </button>
          </div>
        ) : (
          <button
            onClick={handleStarted}
            className="animate-bounce px-4 ml-auto flex-1 hover:animate-none bg-primary h-[40px] rounded-full text-[white] mt-6 flex items-center justify-center gap-5"
          >
            <p>Commencer</p>
            {updateLoading ? (
              <Loader className="animate-spin" />
            ) : (
              <ArrowRight />
            )}
          </button>
        )}
      </Modal>
    </div>
  );
}

export default DiagnostiqueHistoriquePage;
