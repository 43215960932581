import React, { createContext } from "react";
import axios from "axios";
import {
  useLoginUserMutation,
  useRegisterUserMutation,
} from "redux/features/userApi";
import { useDispatch } from "react-redux";
import { CSRF_TOKEN_PATH } from "routes/api.path";
import { baseApi } from "redux/features/apiMananger";
import {
  addCsrfToken,
  addUserId,
  addUserStatus,
  clearAppelOffline,
  clearUserInfo,
} from "redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import { clearParentForm } from "redux/features/parentSlice";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loginUser] = useLoginUserMutation();
  const [registerUser] = useRegisterUserMutation();

  const navigate = useNavigate();

  const from = "/";
  const fromLogout = "/login";

  const dispatch = useDispatch();

  const [isRegisterLoading, setIsRegisterLoading] = React.useState(false);
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const [loginError, setLoginError] = React.useState("");

  const register = async (form) => {
    setIsRegisterLoading(true);
    try {
      const token = await axios.get(CSRF_TOKEN_PATH);
      const user = await registerUser({
        body: { ...form },
        token: token.data?.csrftoken,
      });

      // console.log({ user });

      if (user?.error?.status === 400) {
        return alert("Cet utilisateur existe deja");
      }

      if (user?.error) {
        return alert("Une erreur c'est produite");
      }

      dispatch(addUserId(user?.data?.id));
      dispatch(addCsrfToken(token.data.csrftoken));
      setIsRegisterLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      console.log({ error });
      setIsRegisterLoading(false);
    }
  };

  const login = async (form) => {
    setIsLoginLoading(true);
    try {
      const token = await axios.get(CSRF_TOKEN_PATH);
      // console.log({ token });
      const user = await loginUser({
        body: { ...form },
        token: token.data?.csrftoken,
      });

      // console.log({ user });

      if (user?.error?.status === 401) {
        setIsLoginLoading(false);
        setLoginError(user.error.data.message);
        return { error: user.error.data.message };
      } else {
        dispatch(addUserId(user?.data?.id));
        dispatch(addUserStatus(user?.data?.status));
        dispatch(addCsrfToken(token.data.csrftoken));
        navigate(from, { replace: true });
        setIsLoginLoading(false);
        return { data: user.data };
      }
    } catch (error) {
      // console.log(error);
      setIsLoginLoading(false);
      return { error };
    }
  };

  const logout = async () => {
    try {
      // console.log("logout 1");
      dispatch(clearUserInfo());
      // console.log("logout 2");
      dispatch(clearAppelOffline());
      // console.log("logout 3");
      dispatch(clearParentForm());
      dispatch(baseApi.util.resetApiState());
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        isLoginLoading,
        loginError,
        register,
        isRegisterLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
