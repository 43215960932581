import React from "react";
import styles from "assets/styles.module.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Menu2 } from "tabler-icons-react";
import Credit from "./Credit";
import { DrawerContext } from "context/DrawerContext";
import useGetAssurance from "hooks/useGetAssurance";
import { useSelector } from "react-redux";
import { getParentForm } from "redux/features/parentSlice";
import useGetParent from "hooks/useGetParent";

function PageHeader({ title, path, canShow = true }) {
  const navigation = useNavigate();
  const { handleOpen } = React.useContext(DrawerContext);

  const user = useGetParent();
  // console.log({ user });

  const parent = useSelector(getParentForm);

  const app = useGetAssurance();

  return (
    <div className={styles.pageHeader} style={{ backgroundColor: parent?.type === "CONFIRMED" ? undefined : "#2A65E5" }}>
      <ArrowLeft
        style={{
          cursor: "pointer",
        }}
        size={29}
        onClick={() => navigation(path ?? -1)}
      />
      {parent?.type === "CONFIRMED" ? (
        <h3 className="text-md font-bold">{user?.fullName}</h3>
      ) : canShow ? (
        <h3 className="text-md font-bold">
          {"Démo Test (" + user?.fullName + ")"}
        </h3>
      ) : (
        <h3 className="text-md font-bold">
          {title ?? "Démo Test (" + user?.fullName + ")"}
        </h3>
      )}

      {canShow ? (
        <div className="flex items-center gap-2">
          {app === "Senassurance" && parent?.type === "CONFIRMED" ? null : (
            <Credit />
          )}
          <div
            className="p-2 cursor-pointer hover:text-[#999]"
            onClick={handleOpen}
          >
            <Menu2 />
          </div>
        </div>
      ) : (
        <div className=""></div>
      )}
    </div>
  );
}

export default PageHeader;
