import React from "react";
import PropTypes from "prop-types";
import { styled } from "styled-components";

const Header = styled.div`
  grid-area: header;
  height: 50px;
`;
const Footer = styled.div`
  grid-area: footer;
  height: 55px;
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Main = styled.div`
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const Container = styled.div`
  height: 100dvh;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    "header"
    "main"
    "footer";
  ${(props) =>
    `grid-template-rows: ${props.topBar ? "1fr" : "0fr"} 1fr ${
      props.topBar ? "1fr" : "0fr"
    };`}
`;

export const Template = ({
  header,
  footer,
  headerStyle,
  footerStyle,
  children,
  style,
}) => {
  return (
    <Container>
      {header && <Header style={{ ...headerStyle }}>{header}</Header>}
      <Main
        style={{
          ...style,
          padding: 10
        }}
      >
        {children}
      </Main>
      {footer && <Footer style={{ ...footerStyle }}>{footer}</Footer>}
    </Container>
  );
};

Template.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  footerStyle: PropTypes.object,
};
