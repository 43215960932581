import RenderData from "components/RenderData";
import React from "react";
import { Check, MoodEmpty, X } from "tabler-icons-react";

function HistoriqueDetail({ data = [], isLoading }) {
  return (
    <RenderData
      data={data}
      isLoading={isLoading}
      render={(diagnostique) => <HistoriqueItem diagnostique={diagnostique} />}
    />
  );
}

export default HistoriqueDetail;

function DiagnosticDetail({ index, epreuve }) {
  return (
    <div
      className="flex justify-between border-b-2 mb-2 p-2"
      style={{ borderColor: "rgba(0,0,0,0.2)" }}
    >
      <div className="flex items-center gap-2 ">
        <span className="font-bold text-sm">{index + 1} - </span>
        <h1 className="text-sm">{epreuve?.titre}</h1>
      </div>
      <div className="ml-4">
        {epreuve?.note ? (
          <div className="text-xs flex-nowrap flex">
            <span className="font-bold">{epreuve?.note}</span>/{" "}
            {epreuve?.bareme}
          </div>
        ) : epreuve?.is_correct ? (
          <Check color="green" />
        ) : (
          <X color="red" />
        )}
      </div>
    </div>
  );
}

function EmptyDiagnostic() {
  return (
    <div
      className="w-full h-full bg-warnong flex items-center justify-center flex-col rounded-lg p-6"
      style={{ color: "#fff" }}
    >
      <MoodEmpty style={{ borderColor: "rgba(0,0,0,0.2)" }} size={50} />
      <span className="text-center font-bold">
        Il n'y a pas d'epreuves faites sur ce niveau
      </span>
    </div>
  );
}

function SectionLevel({ notes, index }) {
  return (
    <div className="">
      <div
        className=" border-2 border-gray-200 p-2 flex items-center justify-center rounded-lg my-4 font-bold"
        style={{
          borderColor: "rgba(0,0,0,0.2)",
        }}
      >
        Niveau {index}
      </div>

      <RenderData
        data={notes[`${index}`]}
        renderEmpty={<EmptyDiagnostic />}
        render={(epreuve, index) => (
          <DiagnosticDetail key={index} epreuve={epreuve} index={index} />
        )}
      />
    </div>
  );
}

function HistoriqueItem({ diagnostique }) {
  return (
    <div>
      <div className="">
        <span className="font-bold text-sm">detail tentatives</span>
      </div>

      <SectionLevel notes={diagnostique?.notes} index={"1"} />
      <SectionLevel notes={diagnostique?.notes} index={"2"} />
      <SectionLevel notes={diagnostique?.notes} index={"3"} />
    </div>
  );
}
