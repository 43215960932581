import React from "react";

function HacText({ children, size, weight, style, mt, mb, color, ta, bg }) {
  return (
    <p
      style={{
        marginTop: mt,
        marginBottom: mb,
        fontSize: size ?? 14,
        fontWeight: weight ?? 500,
        color: color,
        backgroundColor: bg,
        textAlign: ta,
        ...style,
      }}
    >
      {children}
    </p>
  );
}

export default HacText;
