import React, { useState, useEffect, useCallback, useRef } from "react";
import { ArrowLeft, ArrowRight, Loader, PlayerPlay } from "tabler-icons-react";
import axios from "axios";
import { TTS_API_URL } from "routes/api";

const wordTtype = (word) => {
  const newWord = word.trim();
  if (newWord.split(" ").length > 1) {
    return "Le groupe de mot";
  } else if (newWord.length === 2) {
    return "La syllabe";
  } else if (newWord.length > 2) {
    return "Le mot";
  } else {
    return "La lettre";
  }
};

export const spliterText = (text) => {
  let newText = [];

  if (text?.includes("-")) {
    newText = text?.split("-");
  } else {
    newText = text?.split(" ");
  }

  if (Array.isArray(newText) && newText.length > 0 && newText?.includes("")) {
    newText = newText?.filter((item) => item);
  }

  do {
    newText = newText?.filter((item) => item || item !== " ");
  } while (
    newText && newText.length > 0 &&
    (newText?.includes("") || newText?.includes(" "))
  );

  return newText;
};

const TextToSpeech = ({
  text,
  current,
  handleValidate,
  selectedTextSpeach,
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState("");

  const [audioSrc, setAudioSrc] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    setValue("");
    setCurrentWordIndex(0);
  }, [current]);

  const speakText = async (defaultIndex) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        TTS_API_URL + "tts",
        {
          // text: "veuillez ecrire, le groupe de mots: Bonsoir tout le monde",
          text: `Veuillez écrire en francais, ${wordTtype(
            getCurrentWord(defaultIndex)
          )} : ${getCurrentWord(defaultIndex)}`,
          // text: `${wordTtype(
          //   getCurrentWord()
          // )} a écrire, se prononce : ${getCurrentWord()}`,
          voice: "shimmer",
          model: "tts-1",
        },
        { responseType: "arraybuffer" }
      );

      const blob = new Blob([response.data], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);
      setAudioSrc(url);
    } catch (error) {
      alert("Une erreur c'est produite, reessayer plutard");
      console.error("Error fetching audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (audioSrc && audioRef.current) {
      audioRef.current.load();
      audioRef.current.play();
    }
  }, [audioSrc]);

  const getCurrentWord = (defaultIndex) => {
    const words = spliterText(text);
    if (defaultIndex) {
      return words[defaultIndex]?.trim() ?? "";
    }
    return words[currentWordIndex]?.trim() ?? "";
  };

  const handleNextWord = (e) => {
    e.preventDefault();
    if (currentWordIndex < spliterText(text).length) {
      if (value?.trim().length > 0) {
        handleValidate({ id: currentWordIndex, value: value?.trim() });
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
        setValue("");
        if (currentWordIndex + 1 < spliterText(text).length) {
          speakText(currentWordIndex + 1);
        }
      } else if (
        selectedTextSpeach?.find((item) => item?.id === currentWordIndex)
          ?.value &&
        selectedTextSpeach?.find((item) => item?.id === currentWordIndex)?.value
          ?.length > 0
      ) {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
        speakText(currentWordIndex + 1);
      } else {
        alert("Veuillez ecrire un mot");
      }
    }
  };

  const handlePrevWord = () => {
    if (currentWordIndex > 0) {
      setCurrentWordIndex((prevIndex) => prevIndex - 1);
      speakText(`${currentWordIndex - 1}`);
    }
  };

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <>
      {audioSrc && (
        <audio
          ref={audioRef}
          className="hidden"
          controls
          src={audioSrc}
        ></audio>
      )}
      <form className="w-full p-2 mt-6">
        <div className="text-center">
          <button
            onClick={(e) => speakText()}
            type="button"
            className="bg-primary rounded-full p-6 text-white cursor-pointer disabled:opacity-70"
            disabled={
              currentWordIndex === spliterText(text)?.length || isLoading
            }
          >
            {isLoading ? <Loader size={20} /> : <PlayerPlay size={20} />}
          </button>
        </div>

        <div className="flex items-center justify-between mt-4 gap-5">
          <button
            disabled={currentWordIndex <= 0 || isLoading}
            onClick={handlePrevWord}
            type="button"
            className="bg-secondary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40 w-full flex text-center items-center justify-center gap-x-4"
          >
            <ArrowLeft />
            Retour
          </button>
          <button
            onClick={handleNextWord}
            type="submit"
            disabled={
              currentWordIndex === spliterText(text)?.length || isLoading
            }
            className="bg-secondary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40 w-full flex text-center items-center justify-center gap-x-4"
          >
            Suivant
            <ArrowRight />
          </button>
        </div>

        <div>
          <input
            type="text"
            className="border-[1px] border-gray w-full p-2 rounded-lg outline-none text-center text-2xl h-[150px] mt-6"
            onChange={handleChange}
            placeholder="Ecrire ici"
            value={value}
            autoComplete={false}
            autoCorrect={false}
          />
        </div>
      </form>
    </>
  );
};

export default TextToSpeech;
