import RenderData from "components/RenderData";
import React from "react";

function HistoriqueItems({ onClick, data = [], isLoading }) {
  return (
    <RenderData
      data={data}
      isLoading={isLoading}
      render={(history, index) => (
        <HistoriqueCard key={index} onClick={onClick} history={history} />
      )}
    />
  );
}

export default HistoriqueItems;

function SelectionLevel({ notes, index, border = false }) {
  return (
    <div
      className={`w-full flex flex-col items-center ${
        border ? "border-x-[2px]" : null
      }`}
    >
      <span className="text-xs font-bold ml-1">
        <span className="text-[10px]">Niveau</span> {index}
      </span>
      <span className="text-[10px]">
        {notes[`${index}`]?.data.length} exercice
        {notes[`${index}`]?.data.length > 1 ? "s" : ""}
      </span>
    </div>
  );
}

function SectionLevelInfo({ history }) {
  return (
    <div className="flex items-center gap-2 -mt-1">
      <div
        className="w-full"
        style={{
          columnCount: 3,
          columnGap: 0,
        }}
      >
        <SelectionLevel notes={history?.notes} index={"1"} />
        <SelectionLevel notes={history?.notes} index={"2"} border />
        <SelectionLevel notes={history?.notes} index={"3"} />
      </div>
      <span className="text-xs opacity-0">{`${parseInt(
        history?.pourcentage
      )}%`}</span>
    </div>
  );
}

function SectionLevelPercentage({ notes, color, index }) {
  return (
    <div
      style={{
        width: `100%`,
        height: "100%",
      }}
    >
      <div
        style={{
          width: `${notes[`${index}`]?.pourcentage}%`,
          backgroundColor: color,
        }}
        className="h-full"
      ></div>
    </div>
  );
}

function SectionLevelPercentageInfo({ history }) {
  return (
    <div className="flex items-center gap-2 ">
      <div
        className="w-full h-[7px] rounded-xl overflow-hidden border-[1px]"
        style={{
          backgroundColor: "rgba(0,0,0,0.1)",
          borderColor: "rgba(0,0,0,0.2)",
          columnCount: 3,
          columnGap: 0,
        }}
      >
        <SectionLevelPercentage
          notes={history?.notes}
          index={"1"}
          color={"rgba(200,100,100,1)"}
        />
        <SectionLevelPercentage
          notes={history?.notes}
          index={"2"}
          color={"rgba(200,100,00,1)"}
        />
        <SectionLevelPercentage
          notes={history?.notes}
          index={"3"}
          color={"rgba(00,200,5,1)"}
        />
      </div>
      <span className="text-xs">{`${parseInt(history?.pourcentage)}%`}</span>
    </div>
  );
}

function HistoriqueHead({ history }) {
  return (
    <div className="flex justify-between items-center mb-2">
      <h1 className="font-bold text-xs">
        {new Date(history?.date_diagnostic).toLocaleDateString("fr", {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </h1>
      <div
        className="px-2 rounded-full text-xs font-500"
        style={{
          backgroundColor:
            history?.status === "VALIDE" ? "green" : "rgba(0,0,0,0.1)",
          color: history?.status === "VALIDE" ? "#fff" : "#000",
        }}
      >
        {history?.status}
      </div>
    </div>
  );
}

function HistoriqueCard({ onClick, history }) {
  return (
    <div
      className="border-[1px] p-3 rounded-lg border-gray-300 mb-3 cursor-pointer"
      style={{
        borderColor: "rgba(0,0,0,0.2)",
        backgroundColor: "#fff",
      }}
      onClick={() => onClick(history?.tentative)}
    >
      <HistoriqueHead history={history} />

      <SectionLevelPercentageInfo history={history} />

      <SectionLevelInfo history={history} />
    </div>
  );
}
