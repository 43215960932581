// import HacFlex from "components/HacFlex";
// import HacText from "components/HacText";
// import Loader from "components/Loader";
import PageHeader from "components/PageHeader";
import RenderData from "components/RenderData";
import { Template } from "layouts/Template";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useGetEleveQuery } from "redux/features/eleveApi";
import { useSelector } from "react-redux";
import { getCurrentEleve } from "redux/features/eleveSlice";
import CallToactionBtn from "components/CallToactionBtn";
// import Breadcrumbs from "components/Breadcrumbs";

function MatierePage() {
  const params = useParams();

  const studentDemo = useSelector(getCurrentEleve);

  const { data: eleve, isLoading: eleveLoading } = useGetEleveQuery(
    params?.eleveId
  );

  return (
    <Template
      header={<PageHeader />}
      style={{ backgroundColor: "rgba(0,0,0,0.03)" }}
    >
      <RenderData
        data={eleve ? eleve : {}}
        isLoading={eleveLoading}
        render={(_eleve) => (
          <div
            className="flex px-4  gap-2 sticky top-0 z-0 border-b-2 py-2"
            style={{ backgroundColor: "#fff", borderColor: "rgba(0,0,0,0.1)" }}
          >
            <div
              className=""
              style={{
                minWidth: 50,
                height: 50,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <img
                src="/icons/faceBlack.png"
                alt="math"
                style={{ width: "50%" }}
              />
            </div>
            <div className="flex flex-col w-full">
              <h2 className="font-bold">
                {studentDemo?.fullName}
                {/* {eleve?.firstName}
                {eleve?.lastName} */}
              </h2>
              <h4 style={{ fontSize: ".7rem" }}>{studentDemo?.classe}</h4>
              {/* <h4 style={{ fontSize: ".7rem" }}>{eleve?.school?.name}</h4>
              <Divider />
              <ListeNiveaux
                eleve={eleve}
                niveaux={eleve?.niveaux ? eleve?.niveaux : []}
              /> */}
            </div>
          </div>
        )}
      />

      {/* <Breadcrumbs /> */}
      <div className="p-2" style={{}}>
        <Outlet />
      </div>

      <CallToactionBtn />
    </Template>
  );
}

export default MatierePage;
