const { createSlice } = require("@reduxjs/toolkit");

const diagnostiqueSlice = createSlice({
  name: "tentatives",
  initialState: {
    diagnosticId: null,
    coachId: null,
    studentId: null,
    notes: [],
    score: 0,
  },
  reducers: {
    setNewDiagnostic(state, { payload }) {
      state.diagnosticId = payload?.diagnosticId;
      state.coachId = payload?.coachId;
      state.studentId = payload?.studentId;
    },
    setEpreuveNote(state, { payload }) {
      const epreuveIndex = state.notes.findIndex(
        (epreuve) => epreuve?.epreuveId === payload?.epreuveId
      );

      if (epreuveIndex !== -1) {
        state.notes[epreuveIndex].note = payload?.note;
      } else {
        state.notes = [...state.notes, { ...payload }];
      }
    },

    setEscoreNiveau(state, { payload }) {
      state.score = state.score + payload;
    },

    clearNote(state) {
      state.notes = [];
      state.score = 0;
    },

    clearDiagnostique(state) {
      state.diagnosticId = null;
      state.coachId = null;
      state.studentId = null;
      state.notes = [];
      state.score = 0;
    },
  },
});

export const {
  clearDiagnostique,
  clearNote,
  setNewDiagnostic,
  setEpreuveNote,
  setEscoreNiveau,
} = diagnostiqueSlice.actions;
export default diagnostiqueSlice.reducer;
