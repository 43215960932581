/**
 *
 * @returns 'Senassurance' | 'Genimi'
 */
function useGetAssurance() {
  const domaine = document.location?.origin ?? "";
  return domaine.startsWith(
    "https://senassurance.genimi.io" || "http://senassurance.genimi.io"
  )
    ? "Senassurance"
    : "Genimi";
}

export default useGetAssurance;
