import React from "react";
import PropTypes from "prop-types";

const ExerciceProgressIndicator = ({ currentStep, totalSteps }) => {
  const percentage = (currentStep / totalSteps) * 100;
  return (
    <>
      <div className="flex items-center w-full max-w-xs mx-auto">
        {[...Array(totalSteps)].map((_, index) => (
          <div key={index} className="flex-auto">
            <div className="text-center text-sm font-bold mt-1 ">
              Ex{index + 1}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <div className="w-full h-3 bg-gray max-w-xs mx-auto my-2 rounded-lg overflow-hidden">
          <div
            className="bg-primary text-xs leading-none h-3 rounded-full transition-width duration-500 ease-in-out"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="font-bold text-sm">
          {`${currentStep}/${totalSteps}`}
        </span>
      </div>
    </>
  );
};

ExerciceProgressIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ExerciceProgressIndicator;
