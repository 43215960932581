import { ApiMananger } from "./apiMananger";

const classeApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getClasses: build.query({
      query: () => `/classes/main/`,
      providesTags: ["Classes"],
    }),
    getClassesAll: build.query({
      query: () => `/classes/`,
      providesTags: ["Classes"],
    }),
  }),
});

export const { useGetClassesQuery, useGetClassesAllQuery } = classeApi;
