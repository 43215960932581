import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Camera,
  Loader,
  Message,
  Send,
  User,
  X,
} from "tabler-icons-react";

const Questions = [
  { id: 1, question: "Que signifi Bakeli ?", answer: null },
  { id: 2, question: "Ou se trouve Bakeli ?", answer: null },
  { id: 3, question: "Il y a combien de coachs a bakeli ?", answer: null },
];

function ChatPage() {
  const navigation = useNavigate();

  const [answer, setAnswer] = useState("");
  const [active, setactive] = useState(1);
  const [chat, setChat] = useState([...Questions]);
  const [loading, setloading] = useState(false);

  const handleSendAnswer = () => {
    let newChat = [...chat];
    const findactiveQuestionIndex = newChat.findIndex(
      (item) => item.id === active
    );

    const makeAnswer = () => {
      newChat[findactiveQuestionIndex].answer = answer;
      setChat([...newChat]);
      setAnswer("");
      setloading(true);
    };

    if (findactiveQuestionIndex !== -1) {
      setTimeout(makeAnswer, 100);
      clearTimeout(makeAnswer);
      const incrementActiveAnswer = () => {
        if (active <= chat.length) {
          setactive(active + 1);
        } else {
          // alert("End of Chat");
        }
        setloading(false);
      };
      setTimeout(incrementActiveAnswer, 2000);
      clearTimeout(incrementActiveAnswer);
    }
  };

  return (
    <div className="relative h-screen">
      <div className="p-2 flex items-center gap-2 bg-white">
        <div className="p-2" onClick={() => navigation(-1)}>
          <ArrowLeft />
        </div>
        <div className="h-10 w-10 rounded-full bg-[#9993] flex items-center justify-center">
          <User size={18} />
        </div>
        <h3 className="font-bold text-[17px]">Mr Sow</h3>
      </div>
      <div className="p-4 flex flex-col gap-3">
        {chat.map((item) =>
          item?.id <= active ? (
            <>
              <div className="flex">
                <p className="bg-[#9993] rounded-[30px] rounded-bl-sm px-4 py-2 max-w-[90%]">
                  <p className="font-bold text-sm"> Question {item?.id}</p>
                  {item?.question}
                </p>
              </div>
              {item?.answer && (
                <div className="flex justify-end">
                  <p className="rounded-[30px] text-white rounded-br-sm px-4 py-2 max-w-[90%] mb-2 bg-secondary">
                    {item?.answer}
                  </p>
                </div>
              )}
              {loading && active === item?.id && (
                <Loader className="animate-spin" />
              )}
            </>
          ) : null
        )}

        {active === chat.length + 1 && (
          <div className="border-[1px] border-[#999] rounded-[10px] p-2 mt-6">
            <div className="flex items-center justify-between my-2">
              <h3 className="font-bold">Recapitulation</h3>
              <h3 className="font-bold">8/10</h3>
            </div>

            <div className="flex flex-col gap-4 my-4">
              {chat.map((item) => (
                <div className="flex items-center gap-4" key={item?.id}>
                  <p className="font-bold text-sm">{item?.id} - </p>
                  <p className="">{item?.question}</p>
                  <X color="red" />
                </div>
              ))}
            </div>
            <div
              className="border-[1px] border-[#999] rounded-[20px] p-2 w-[100px] mt-2 text-white bg-secondary"
              onClick={() => {
                setactive(1);
                setChat([...Questions]);
              }}
            >
              Reessayer
            </div>
          </div>
        )}

        {/* <p className="bg-[#9993] rounded-[30px] rounded-bl-sm p-4">
          Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Mollitia ipsa, a deleniti laudantium
          inventore perferendis aspernatur aliquid cupiditate corporis
          praesentium sit voluptatem neque sed odit velit ipsam eum est
          corrupti!
        </p> */}
      </div>
      <div className="fixed bottom-0 bg-white w-full flex items-center gap-2 py-3 flex-1">
        <div className="p-3">
          <Camera onClick={() => navigation("/camera")} />
        </div>
        <div className="border-[1px] border-[#999] bg-white rounded-[20px] overflow-hidden flex items-center">
          <div className="pl-4 pr-2">
            <Message size={18} />
          </div>
          <input
            placeholder="ecrire ici"
            className="h-[35px] flex-1 outline-none"
            autoFocus
            onChange={(e) => setAnswer(e.target.value)}
            value={answer}
            disabled={active === chat.length + 1 || loading}
          />
        </div>
        <div
          className="p-3"
          onClick={() => (answer ? handleSendAnswer() : null)}
        >
          <Send />
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
