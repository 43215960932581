import PageHeader from "components/PageHeader";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Template } from "layouts/Template";
import { X } from "tabler-icons-react";

function PayementErrorPage() {
  const navigation = useNavigate();

  return (
    <Template
      header={<PageHeader title={"Paiement Error"} />}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 20,
        padding: 10,
      }}
    >
      <div className="flex flex-col gap-4 items-center">
        <div className=" w-10 h-10 flex items-center justify-center rounded-full bg-[red] shadow-lg">
          <X color="#fff" size={25} />
        </div>
        <div className="text-[25px]">Erreur l'or de votre paiement</div>
      </div>
      <div
        className="flex items-center justify-center cursor-pointer gap-5 text-white p-3 my-4 bg-[#1AB3E5] rounded-full mx-3"
        data-testid="cypress-welcom-button"
        onClick={() => navigation("/", { replace: true })}
      >
        Retour a la page d'accueil
      </div>
    </Template>
  );
}

export default PayementErrorPage;
