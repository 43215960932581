export function compareText(choices = "", correction = "", bareme) {
  const textToArray = choices.split(" ");
  const correctionToArray = correction.split(" ");
  const note = bareme / correctionToArray.length;

  let score = 0;
  let scoreTable = [];
  for (let i = 0; i < textToArray.length; i++) {
    for (let j = 0; j < correctionToArray.length; j++) {
      if (
        textToArray[i] === correctionToArray[j] &&
        !scoreTable.find((item) => item === textToArray[i])
      ) {
        scoreTable = [...scoreTable, correctionToArray[j]];
        score += note;
        break;
      }
    }
  }
  return { score, length: correctionToArray.length, scoreTable };
}

// // Fonction pour calculer la note finale sur 10
// export function calculerNoteFinale(corrections, bareme) {
//   // Somme des notes
//   const somme = corrections.reduce((acc, note) => acc + note?.score, 0);

//   // Moyenne des notes
//   const moyenne = somme / corrections.length;

//   // Note finale sur 10
//   const noteFinale = moyenne * bareme;

//   return noteFinale;
// }

const converMinuteToSeconde = (minute) => {
  return minute * 60;
};

// Fonction pour calculer la note finale sur 10
export function calculerNoteFinaleByPercent(corrections, bareme) {
  // Moyenne des notes
  const moyenne = corrections * bareme;

  // Note finale sur 10
  const noteFinale = moyenne / 100;

  return noteFinale;
}

// Fonction pour calculer la note finale sur 10
export function calculerNoteTextToSpeachWithTimer(
  corrections,
  bareme,
  minute,
  secondeLue
) {
  // Moyenne des notes
  const moyenne = corrections * bareme;

  // Note finale sur 10
  const noteFinale = moyenne / 100;

  if (converMinuteToSeconde(minute) > secondeLue) {
    return noteFinale;
  } else {
    return 0;
  }
}

export function compareTextWithPosition(choices = "", correction = "", bareme) {
  // console.log({ choices, correction, bareme });
  const textToArray = choices
    .split(" ")
    .filter((item) => item !== "")
    .map((value, key) => ({ key, value }));
  const correctionToArray = correction
    .split(" ")
    .filter((item) => item !== "")
    .map((value, key) => ({ key, value }));
  const note = bareme / correctionToArray.length;
  // console.log({ correctionToArray, textToArray });
  let score = 0;
  let scoreTable = [];
  for (let i = 0; i < textToArray.length; i++) {
    for (let j = 0; j < correctionToArray.length; j++) {
      if (
        textToArray[i].key === correctionToArray[j].key &&
        textToArray[i].value === correctionToArray[j].value &&
        !scoreTable.find((item) => item === textToArray[i])
      ) {
        scoreTable = [...scoreTable, correctionToArray[j].value];
        score += note;
        break;
      }
    }
  }
  return { score, length: correctionToArray.length, scoreTable };
}

export function correctFigure(choices = "", correction = "", bareme) {
  // console.log({ choices, correction, bareme });

  if (choices === correction) {
    return bareme;
  }
  return 0;
}

export function correctTsq(choices = [], correction = [], bareme) {
  let score = 0;
  correction.forEach((question) => {
    choices.forEach((choice) => {
      if (question?.id === choice?.questionId) {
        if (
          question?.responses?.find((item) => item?.id === choice?.id)
            ?.isCorrect
        ) {
          score += 1;
        }
      }
    });
  });

  const poucentage = (score / correction.length) * 100;

  const note = (poucentage * bareme) / 100;

  return Math.round(note);
}

export function correctionLettreManquante(choices, bareme) {
  let pointsObtenus = 0;
  // console.log({ choices });
  for (let i = 0; i < choices.length; i++) {
    let choixUtilisateur = choices[i]?.choice?.toLowerCase();
    let correction = choices[i]?.correction?.toLowerCase();

    // Comparer le choix de l'utilisateur avec la correction
    if (choixUtilisateur === correction) {
      pointsObtenus += 1;
    }
  }

  // Calculer la moyenne
  let moyenne = pointsObtenus / choices.length;

  return Math.round(moyenne * bareme);
}

export function correctCompterPar5(correction_diagnostic=[], userCorrectionProposition = [], bareme) {

  // Vérifier si les longueurs sont différentes et si tous les éléments sont identiques
  if (correction_diagnostic.length === userCorrectionProposition.length && correction_diagnostic.every((value, index) => value === userCorrectionProposition[index])) {
    return bareme;
  }
  return 0;
}

export function correctionLettreManquanteLength(choices) {
  let pointsObtenus = 0;

  for (let i = 0; i < choices.length; i++) {
    let choixUtilisateur = choices[i]?.choice;
    // console.log({ choixUtilisateur });
    if (choixUtilisateur !== "") {
      pointsObtenus += 1;
    }
  }

  if (choices.length > 0) {
    if (pointsObtenus === choices.length) {
      return true;
    }
  }

  return false;
}

export function compareNote(bareme, note) {
  const pourcentage = (note * 100) / bareme;

  let analyse = "";
  let feeling = "";
  let color = "yellow";
  let icon = "";
  let identifiant = null;
  let interpretation = "";
  let recommandation = "";

  if (pourcentage < 50) {
    analyse = "mediocre";
    interpretation = "Mon Enfant ne peut pas :";
    feeling = "😣 😓";
    color = "red";
    icon = "❌";
    identifiant = 1;
    recommandation =
      "Si vous voulez aller plus loin, Génimi pourrait  aider votre enfant à faire les devoirs à la maison sans stress et préparer les évaluations sereinement pour avoir de meilleures note rapidement.";
  } else if (pourcentage < 60) {
    analyse = "passable";
    interpretation = "Mon Enfant dois s'exercer sur :";
    feeling = "🤕 😮‍💨";
    color = "orange";
    icon = "🟠";
    identifiant = 2;
    recommandation =
      "Si vous voulez aller plus loin, Génimi pourrait  aider votre enfant à faire les devoirs à la maison sans stress et préparer les évaluations sereinement pour avoir de meilleures note rapidement.";
    // "Votre enfant dois faire des efforts pour ce type d'epreuve";
  } else if (pourcentage < 80) {
    analyse = "bien";
    interpretation = "Mon Enfant peut :";
    feeling = "🫡 🤠";
    color = "green";
    icon = "✅";
    identifiant = 3;
    recommandation =
      "Votre enfant dispose des bases nécessaires pour ce type d'epreuve. Si vous voulez aller plus loin, Génimi pourrait  aider votre enfant à améliorer ses notes en classe.";
  } else {
    analyse = "Très bien";
    interpretation = "Mon Enfant peut :";
    feeling = "🥳 🤩";
    color = "green";
    icon = "✅";
    identifiant = 3;
    recommandation =
      "Votre enfant dispose des bases nécessaires pour ce type d'epreuve. Si vous voulez aller plus loin, Génimi pourrait  aider votre enfant à améliorer ses notes en classe.";
  }

  return {
    message: analyse,
    interpretation,
    feeling,
    color,
    icon,
    identifiant,
    recommandation,
  };
}

export function calculePourcentageReussiteParNiveau(niveaux = []) {
  if (niveaux.length > 0) {
    const baremeTotal = niveaux.reduce((acc, cur) => acc + cur.bareme, 0);
    const noteTotal = niveaux.reduce((acc, cur) => acc + cur.note, 0);

    return Math.round((noteTotal * 100) / baremeTotal);
  }

  return 0;
}

export function calculePourcentageReussiteDiagnostique(n1, n2, n3) {
  return Math.round((n1 + n2 + n3) / 3);
}

// const data = compareText(
//   "bonjour tout le monde vous allez",
//   "bonjour tout le monde vous",
//   21
// );

// console.log({ data });
