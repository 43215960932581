import React, { useCallback } from "react";

export const DrawerContext = React.createContext();

export const DrawerProvider = ({ children }) => {
  const [opened, setOpened] = React.useState(false);

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => setOpened(false), []);

  return (
    <DrawerContext.Provider value={{ handleClose, handleOpen, opened }}>
      {children}
    </DrawerContext.Provider>
  );
};
