export const progressImage = [
  "crawl.png",
  "fille1.png",
  "retour-a-lecole.png",
  "fille.png",
  "garcon.png",
  "fille2.png",
];

export const explaineImage = [
  "1.gif",
  "2.gif",
  "3.gif",
  "4.gif",
  "5.gif",
  "6.gif",
  "7.gif",
  "8.gif",
  "9.gif",
  "10.gif",
  "11.gif",
  "12.gif",
  "13.gif",
  "14.gif",
  "15.gif",
  "16.gif",
  "17.gif",
  "18.gif",
  "19.gif",
  "20.gif",
  "21.gif",
  "22.gif",
  "23.gif",
  "24.gif",
  "25.gif",
];
