import Divider from "../pages/matiere/components/Divider";
import ListeNiveaux from "./ListeNiveaux";

function EleveCard({ onClick, firstName, lastName, classe, school, niveaux }) {
  return (
    <div
      className=""
      style={{
        display: "flex",
        padding: 10,
        gap: 10,
        backgroundColor: "#fff",
        borderRadius: 10,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.08)",
        marginBottom: 15,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div
        className=""
        style={{
          minWidth: 40,
          height: 40,
          borderRadius: 30,
          backgroundColor: "rgba(0,0,0,0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="icons/faceBlue.png" alt="face" style={{ width: "50%" }} />
      </div>
      <div className="w-full">
        <h5 className="font-bold text-sm">
          {firstName} {lastName}
        </h5>
        <p className="text-xs">{classe?.name}</p>
        <p className="text-xs">{school?.name}</p>
        <Divider />
        <ListeNiveaux niveaux={niveaux} />
      </div>
    </div>
  );
}

export default EleveCard;
