import Timeline from "components/Timeline";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowLeft, User, X } from "tabler-icons-react";
import TextToSpeech from "./TextSpeach";
import Credit from "components/Credit";
import Figure from "./Figure";
import TextSpeachWriteInNumber from "./TextSpeachWriteInNumber";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 5px;
//   // height: 60px;
//   background-color: blue;
// `;

const ContentWrapper = styled.div`
  /* Ajoutez des styles pour la disposition des éléments à l'intérieur */
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  min-width: 40px;
  padding: 0 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const AnswerArea = styled.div`
  border: 2px dashed #ccc;
  ${(props) => (props.correct === "false" ? "background-color: red;" : null)}
  border-radius: 8px;
  min-width: 50px;
  padding: 0 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InputAnswerArea = styled.input`
  border: ${(props) => props.disabled ? "3px dashed #333" : "2px dashed #ccc"};
  font-weight: ${(props) => props.disabled ? "normal" : "800"};
  font-size: ${(props) => props.disabled ? "14px" : "18px"};
  ${(props) => (props.correct === "false" ? "background-color: red;" : null)}
  border-radius: 8px;
  width: 55px;
  padding: 0 5px;
  height: 50px;
  flex: 1;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

const InputAnswerAreaForLetters = styled.input`
  border: ${(props) => props.disabled ? "3px dashed #333" : "2px dashed #ccc"};
  font-weight: ${(props) => props.disabled ? "normal" : "800"};
  font-size: ${(props) => props.disabled ? "12px" : "16px"};
  ${(props) => (props.correct === "false" ? "background-color: red;" : null)}
  border-radius: 8px;
  width: 120px;
  padding: 7px 5px;
  height: 60px;
  flex: 1;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

const MathGame = ({
  completDiagnostic,
  currentLevel,
  current,
  selected,
  handleAddWord,
  handleRemoveWord,
  userAnswer,
  setUserAnswer,
  handleCheck,
  eleve,
  studentDemo,
  randomeImage,
  handleGoBack,
  onTextRecorded,
  textRecorded,
  onSelecteFigure,
}) => {
  const handleDrop = (e, targetValue) => {
    e.preventDefault();
    const droppedValue = e.dataTransfer.getData("cardValue");

    // Vérifier si la valeur déposée correspond à la cible
    if (droppedValue === targetValue) {
      handleAddWord({ [targetValue]: droppedValue }); // Ajout dans les réponses utilisateur
      if (window.navigator.vibrate) {
        window.navigator.vibrate(100); // Effet de vibration
      }
    }
  };


  // const handleAddWord = (word, targetIndex) => {
  //   let updatedAnswer;

  //   // Si userAnswer est un objet, on le copie
  //   if (userAnswer && typeof userAnswer === "object" && !Array.isArray(userAnswer)) {
  //     updatedAnswer = { ...userAnswer };  

  //     // Ajouter ou mettre à jour la réponse au bon index ou à la clé spécifiée
  //     if (targetIndex !== undefined) {
  //       updatedAnswer[targetIndex] = word;
  //     } else {
  //       updatedAnswer[word] = word;  // Pour des clés simples
  //     }
  //   } else if (typeof userAnswer === "string") {
  //     updatedAnswer = userAnswer + " " + word;
  //   } else {
  //     // Si c'est autre chose (par exemple, null), on transforme en tableau
  //     updatedAnswer = [word];
  //   }

  //   // Mettre à jour l'état avec la nouvelle réponse, éviter d'avoir un objet dans le state
  //   handleCheck(updatedAnswer);
  // };



  const handleDrag = (e, cardValue) => {
    e.dataTransfer.setData("cardValue", cardValue); // Définit la donnée transférée
  };


  const handleDragOver = (e) => {
    e.preventDefault(); // Permet le glisser-déposer
  };


  // console.log(data[currentLevel].data, "donnee", { current });
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <GameHeader
          handleGoBack={handleGoBack}
          eleve={eleve}
          current={current}
          data={completDiagnostic[currentLevel]}
          randomeImage={randomeImage}
          studentDemo={studentDemo}
        />
        <MathCardContainer
          // TODO: rename data to levelExercices
          data={completDiagnostic[currentLevel]?.data}
          // TODO: rename item to currentExercice
          item={completDiagnostic[currentLevel]?.data[current]}
          current={current}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleRemoveWord={handleRemoveWord}
          userAnswer={userAnswer}
          setUserAnswer={setUserAnswer}
          handleAddWord={handleAddWord}
          selected={selected}
        />
      </div>
      {completDiagnostic && (
        <MathGameControls
          choices={completDiagnostic[currentLevel]?.data[current]?.choices}
          correction_dp={completDiagnostic[currentLevel]?.data[current]?.correction_dp}
          type={completDiagnostic[currentLevel]?.data[current]?.type}
          consigne={completDiagnostic[currentLevel]?.data[current]?.consigne}
          handleAddWord={handleAddWord}
          handleCheck={handleCheck}
          handleDrag={handleDrag}
          onTextRecorded={onTextRecorded}
          selected={selected}
          textRecorded={textRecorded}
          userAnswer={userAnswer}
          current={current}
          currentLevel={currentLevel}
          data={completDiagnostic}
          onSelecteFigure={onSelecteFigure}
        />
      )}
    </div>
  );
};

// Le reste du code reste le même

export default MathGame;

export const GameHeader = ({
  handleGoBack,
  eleve,
  current,
  data,
  randomeImage,
  studentDemo,
}) => {
  return (
    <ContentWrapper className="">
      <Header
        handleGoBack={handleGoBack}
        eleve={eleve}
        studentDemo={studentDemo}
      />

      <Timeline data={data} />
      <ProgressIndicator
        current={current}
        data={data?.data}
        randomeImage={randomeImage}
      />
    </ContentWrapper>
  );
};

export const Header = ({ handleGoBack, eleve, type, studentDemo }) => {
  return (
    <div className="flex justify-between items-center  py-2 h-[80px] sticky top-0 bg-white z-30">
      <div className="flex gap-2 mt-[-10px]">
        <div
          className="cursor-pointer flex items-center justify-center px-1"
          onClick={handleGoBack}
        >
          <ArrowLeft />
        </div>
        <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
          <User color="#fff" size={18} />
        </div>
        <div className="text-sm">
          <p className="-mb-1 font-bold">
            {/* {eleve?.firstName}
              {eleve?.lastName} */}
            {studentDemo?.fullName}
          </p>
          <p className="text-xs">{studentDemo?.classe}</p>
        </div>
      </div>
      <div className="flex flex-col items-end gap-2 justify-center scale-90">
        <Credit />
        <h2
          className="text-center px-3 py-1 rounded-2xl text-xs"
          style={{ backgroundColor: "rgba(0,0,0,.1)" }}
        >
          <p className="scale-90">Diagnostic {type ?? "Mathematique"}</p>
        </h2>
      </div>
    </div>
  );
};

export const ProgressIndicator = ({ current, data, randomeImage }) => {
  return (
    <div className="flex gap-2" style={{ marginTop: 0 }}>
      <div className="w-full">
        <div
          className=""
          style={{
            borderRadius: 10,
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            className="relativ flex justify-end"
            style={{
              width: `${(current * 100) / data.length > 0
                ? (current * 100) / data.length
                : 5
                }%`,
              transition: "width 1s ease-in-out",
            }}
          >
            <img
              src={`../../../../assets/kids/${randomeImage}`}
              alt="progress"
              className="w-6"
            />
          </div>
        </div>
        <div
          className=""
          style={{
            borderRadius: 10,
            height: 8,
            width: "100%",
            marginBottom: 20,
            backgroundColor: "rgba(0,0,0,0.1)",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            className=""
            style={{
              width: `${(current * 100) / data.length}%`,
              height: "100%",
              backgroundColor: "rgb(100,200,50)",
              transition: "width 1s ease-in-out",
            }}
          ></div>
        </div>
      </div>
      <div className="flex items-center font-bold text-xs">
        {current + 1}/{data.length}
      </div>
    </div>
  );
};

const MathCardContainer = ({
  data,
  current,
  handleDrop,
  handleDragOver,
  handleRemoveWord,
  userAnswer,
  setUserAnswer,
  selected,
  handleAddWord,
  item,
}) => {
  // console.log({ item , data});

  const [consigneValues, setConsigneValues] = useState(() => {
    const parseConsignes = () => item?.consigne?.map((consigneItem) => JSON.parse(consigneItem)) || [];
    const values =
      item?.type !== 'resolutionProbleme' &&
      (item?.type !== 'completerEnonces' ? parseConsignes() : []);

    return values?.length ? values : [];
  })

  // Initialize consigne values on item change
  useEffect(() => {
    if (item && item.consigne) {
      const values = item.consigne.map((consigneItem) => {
        try {
          const parsedItem = item.type === 'completerEnonces' || 'resolutionProbleme' ? consigneItem : JSON.parse(consigneItem);
          return { ...parsedItem, userValue: "" }; // Initialize userValue
        } catch (error) {
          console.error("Failed to parse consigne item:", consigneItem, error);
          return { type: "number", value: "", userValue: "" }; // Default to an empty object
        }
      });
      setConsigneValues(values);
    } else {
      setConsigneValues([]);
    }
  }, [item]);


  // Update userAnswer based on consigneValues
  useEffect(() => {
    const updatedUserAnswer = consigneValues
      ?.filter((consigne) => consigne) // Filter out undefined or null items
      .map((consigne) => consigne.userValue || consigne.value || "") // Safely access properties
      .filter((value) => value !== ""); // Filter out empty strings
    setUserAnswer(updatedUserAnswer);
  }, [consigneValues, setUserAnswer]);



  return (
    <div style={{ padding: 20 }}>
      {data.length > 0 && (
        <div>
          <div className="flex gap-2">
            <img
              src={`../../../../assets/kids/${item?.type === "transcription"
                ? "imp2"
                : item?.type === "alphabet"
                  ? "alphabet"
                  : "fille2"
                }.png`}
              alt="progress"
              className="w-20"
            />
            <div className="flex items-end">
              <h4 className="text-sm">
                <b>Exercice: {" "}</b>
                {(item?.type !== "numberDictation") || (item.type !== "win") || item.type !== "wil" && (item?.titre)}
                {item?.type === "win" || item?.type === "wil"
                  ? ", puis séparez-les par des virgules."
                  : null}
                {item?.type === "numberDictation" ? `${item?.titre}: Écris le nombre que tu as entendu (audio)` : null}
                {item?.type === "completerEnonces" || "resolutionProbleme" ? `${item?.titre}` : null}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "column",
              gap: 15,
              marginTop: 10,
              flexWrap: "wrap"
            }}
          >
            {/* Type 'missingNumbers' */}
            {item?.type === "missingNumbers" ? (
              <div>
                <p style={{ marginBottom: 7 }}>Remplissez les cases vides</p>
                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                  {
                    item?.consigne
                      .map((consigneItem) => JSON.parse(consigneItem))
                      .map((parsedItem, index) => {
                        return (
                          <div key={index}>
                            {parsedItem.value !== "" ? (
                              <InputAnswerArea
                                disabled
                                value={parsedItem.value}
                              />
                            ) : (
                              <>
                                <InputAnswerArea
                                  value={consigneValues[index]?.value || ""}
                                  type={"text"}
                                  placeholder="" // Optional placeholder for clarity
                                  onBlur={(event) => {
                                    handleAddWord(event.target.value); // Add the user's value
                                  }}
                                  onChange={(event) => {
                                    const newConsigneValues = [...consigneValues];
                                    const consigneValue = { ...consigneValues[index], value: event.target.value };
                                    newConsigneValues[index] = consigneValue;
                                    setConsigneValues(newConsigneValues);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        );
                      })
                  }
                </div>
              </div>
            ) : item?.type === "wordToNumbers" || item?.type === "numberToWords" ? (
              item?.consigne
                .map((consigneItem) => JSON.parse(consigneItem))
                .map((parsedItem, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontSize: 23, fontWeight: "bold" }}>
                      {parsedItem?.value ?? "Valeur manquante"}
                    </div>
                    <InputAnswerAreaForLetters
                      type="text"
                      value={consigneValues[index]?.userValue || ""}
                      placeholder="" // Optional placeholder
                      onChange={(event) => {
                        const newConsigneValues = [...consigneValues];
                        const consigneValue = { ...consigneValues[index], userValue: event.target.value };
                        newConsigneValues[index] = consigneValue;
                        setConsigneValues(newConsigneValues);
                      }}
                      onBlur={(event) => {
                        handleAddWord(event.target.value);
                      }}
                    />
                  </div>
                ))
            ) : item?.type === "operation" ? (
              // Type 'operation'
              item?.consigne
                .map((consigneItem) => JSON.parse(consigneItem))
                .map((parsedItem, index) => {
                  return (
                    <div className="" key={index}>
                      {data[current]?.correction_dp.includes(parsedItem?.value) ? (
                        <AnswerArea
                          onDrop={(e) => {
                            e.preventDefault();
                            const droppedValue = e.dataTransfer.getData("cardValue");

                            // Update userAnswer to the dropped card value
                            setUserAnswer(droppedValue); // Save the dropped card value as the user answer

                            if (window.navigator.vibrate) {
                              window.navigator.vibrate(100); // Provide feedback
                            }
                          }}
                          onDragOver={handleDragOver}
                        >
                          <b style={{ fontSize: 23 }}>
                            {/* Check if userAnswer contains "=" and display "?" */}
                            {userAnswer?.includes("=") ? "?" : userAnswer || "?"}
                          </b>
                        </AnswerArea>
                      ) : (
                        <Card
                          draggable
                          onDragStart={(e) => e.dataTransfer.setData("cardValue", parsedItem.value)}
                        >
                          {parsedItem?.value.toString()}
                        </Card>
                      )}
                    </div>
                  );
                })
              // Type 'operation2'
            ) : item?.type === "numberDictation" ? (
              item?.consigne
                .map((consigneItem) => JSON.parse(consigneItem))
                .map((parsedItem, index) => {
                  console.log(item?.consigne.map((consigneItem) => JSON.parse(consigneItem)));
                  return (
                    <div className="operation2-container" key={index}>
                      {data[current]?.correction_dp.includes(
                        parsedItem?.value
                      ) ? (
                        <AnswerArea
                          onDrop={(e) =>
                            handleDrop(e, e.dataTransfer.getData("cardValue"))
                          }
                          onDragOver={handleDragOver}
                        >
                          <b style={{ fontSize: 23 }}>{userAnswer ?? "?"}</b>
                        </AnswerArea>
                      ) : (
                        <div className="flex flex-col gap-10 pt-16">

                          <TextSpeachWriteInNumber text={item?.consigne.map((consigneItem) => JSON.parse(consigneItem))} />
                          <InputAnswerAreaForLetters
                            type="text"
                            value={consigneValues[index]?.userValue || ""}
                            placeholder="" // Optional placeholder
                            onChange={(event) => {
                              const newConsigneValues = [...consigneValues];
                              const consigneValue = { ...consigneValues[index], userValue: event.target.value };
                              newConsigneValues[index] = consigneValue;
                              setConsigneValues(newConsigneValues);
                            }}
                            onBlur={(event) => {
                              handleAddWord(event.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })
            ) : item?.type === "completerEnonces" ? (
              <div>
                <p className="py-10">Résolvez en complétant le texte suivant :</p>
                <p style={{ fontSize: 18, lineHeight: 1.8 }}>
                  {item?.consigne.map((word, index) => {
                    // Show an input if the word is part of the correction_dp array
                    return item?.correction_dp?.includes(word) ? (
                      <>
                        <InputAnswerArea
                          key={index}
                          type="text"
                          value={consigneValues[index]?.userValue || ""} // Show user value
                          placeholder="" // Optional placeholder
                          onChange={(event) => {
                            setConsigneValues((prevState) => {
                              const updatedValues = [...prevState];
                              updatedValues[index] = {
                                ...updatedValues[index],
                                userValue: event.target.value,
                              };
                              return updatedValues;
                            });
                          }}
                          onBlur={(event) => {
                            handleAddWord(event.target.value); // Trigger custom blur logic
                          }}
                        /> {" "}
                      </>
                    ) : (
                      <span key={index}>{word} </span>
                    );
                  })}
                </p>
              </div>
            ) : item?.type === "resolutionProbleme" ? (
              <div>
                <p style={{ fontSize: 18, lineHeight: 1.8 }}>{item?.consigne[0]}</p>
                <div>
                  {item?.correction_dp.map((step, index) => (
                    <div key={index} className="pt-3">
                      <InputAnswerAreaForLetters
                        type="text"
                        value={consigneValues[index]?.value || ""}
                        placeholder="Votre réponse"
                        onChange={(event) => {
                          setConsigneValues((prevState) => {
                            const updatedValues = [...prevState];
                            updatedValues[index] = {
                              ...updatedValues[index],
                              value: event.target.value,
                              isAnswered: true,
                            };
                            return updatedValues;
                          });
                        }}
                        onBlur={(event) => {
                          handleAddWord(event.target.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : selected.length === 0 ? (
              // Default fallback
              <span style={{ color: "gray" }}>Cliquez sur un élément ...</span>
            ) : (
              // Sélection des éléments
              <div
                className="border-b-2 w-full pb-2 flex gap-2"
                style={{ borderColor: "rgba(0,0,0,0.1)" }}
              >
                {selected.map((selectedItem, index) => (
                  <Card
                    className=""
                    key={index}
                    onClick={() => handleRemoveWord(selectedItem)}
                  >
                    {selectedItem}
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        width: 10,
                        height: 10,
                        top: -5,
                        right: -5,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <X size={10} color="red" />
                    </div>
                  </Card>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

//! win: Write In Number
//! wil: Write In Letter
// Affichage des choix
const MathGameControls = ({
  type,
  choices,
  handleDrag,
  handleCheck,
  userAnswer,
  handleAddWord,
  selected,
  onTextRecorded,
  textRecorded,
  consigne,
  data,
  onSelecteFigure,
  currentLevel,
  current,
  correction_dp
}) => {

  if (type === "wordToNumbers" || type === "numberToWords" || type === "missingNumbers" || type === "numberDictation" || type === "completerEnonces" || type === 'resolutionProbleme') {
    return null; // Don't render the controls for these types
  }
  return (
    <div
      style={
        {
          // flexDirection: "row",
          // display: "flex",
          // gap: 10,
          // justifyContent: "center",
          // padding: 10,
        }
      }
    // className="bg-primary"
    >
      {type === "operation" ? (
        <div className="flex gap-2 justify-center flex-wrap">
          {choices.map((cardValue, index) => (
            <Card
              key={index}
              draggable
              onDragStart={(e) => handleDrag(e, cardValue)}
              onClick={() => handleCheck(cardValue)}
              style={{ borderColor: userAnswer === cardValue && "green" }}
            >
              {cardValue}
            </Card>
          ))}
        </div>
      ) : type === "numberDictation" ? (
        <div className="flex gap-2 justify-center flex-wrap">
          {choices.map((cardValue, index) => (
            <Card
              key={index}
              draggable
              onDragStart={(e) => handleDrag(e, cardValue)}
              onClick={() => handleCheck(cardValue)}
              style={{ borderColor: userAnswer === cardValue && "green" }}
            >
              {cardValue}
            </Card>
          ))}
        </div>
      ) :
        type === "win" || type === "wil" ? (
          <div className="w-full flex flex-col gap-10">
            <TextToSpeech
              textRecorded={textRecorded}
              onChange={onTextRecorded}
              text={consigne?.join("; ")}
            />
          </div>
        ) : type === "figure" ? (
          <Figure
            data={data[currentLevel]?.data[current] ?? {}}
            onSelecte={onSelecteFigure}
          />
        ) : (
          <div className="flex gap-2 justify-center flex-wrap">
            {consigne.map((cardValue, index) => (
              <Card
                className=""
                key={index}
                onClick={() => handleAddWord(cardValue)}
                style={{
                  opacity: selected.includes(cardValue) ? 0.3 : 1,
                }}
              >
                {cardValue}
              </Card>
            ))}
          </div>
        )}
    </div>
  );
};


