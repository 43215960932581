import Button from "components/Button";
import RenderData from "components/RenderData";
import { Template } from "layouts/Template";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCurrentEleve } from "redux/features/eleveSlice";
import { ArrowLeft, Bulb, User } from "tabler-icons-react";

function ExerciceDetailPage() {
  const params = useParams();
  const navigation = useNavigate();

  const location = useLocation();

  //   const [activeLevel, setActiveLevel] = useState(1);
  const studentDemo = useSelector(getCurrentEleve);

  // const { data: eleve } = useGetEleveQuery(params?.eleveId);

  // useEffect(() => {
  //   console.log({ location });
  // }, []);

  return (
    <Template
      header={
        <div className="flex justify-between items-center px-2 py-4">
          <div className="flex gap-2 items-center">
            <div
              className="cursor-pointer flex items-center justify-center px-1"
              onClick={() => navigation(-1)}
            >
              <ArrowLeft />
            </div>
            <div className="h-8 w-8 bg-primary rounded-full flex justify-center items-center">
              <User color="#fff" size={18} />
            </div>
            <div className="text-md">
              <p className="-mb-1 font-bold">
                {/* {eleve?.firstName} {eleve?.lastName} */}
                {studentDemo?.fullName}
              </p>
              <p className="text-xs tew">{studentDemo?.classe}</p>
            </div>
          </div>
          <h2
            className="text-center px-3 py-1 rounded-xl text-[10px]"
            style={{ backgroundColor: "rgba(0,0,0,.1)" }}
          >
            Diagnostic {params?.name}
          </h2>
        </div>
      }
      headerStyle={{ height: 60 }}
      style={{
        backgroundColor: "#fff",
      }}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            padding: "0px 20px",
            marginTop: 5,
          }}
        >
          {/* <Button
            style={{ backgroundColor: "orange", flex: 1, height: 45 }}
            radius={"sm"}
            onClick={() => navigation(-1)}
          >
            Quitter
          </Button> */}
          <Button
            style={{
              backgroundColor: "#003880",
              flex: 1,
              height: 40,
              borderRadius: 20,
            }}
            radius={"sm"}
            onClick={() => navigation("../contact-genimi")}
          >
            Contactez Genimi
          </Button>
        </div>
      }
    >
      <RenderData
        data={location?.state}
        render={(item) => (
          <>
            <div className="mb-0">
              <div
                className="py-8 px-4 bg-white mt-0 relative text-sm"
                style={{
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.08)",
                }}
              >
                <div className="flex gap-3 border-2 p-2 border-gray rounded-xl">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: location?.state?.analyse?.color }}
                  ></div>
                  <div className="">
                    <p className="font-bold">
                      {location?.state?.analyse?.interpretation.toUpperCase()}
                    </p>
                    <div className="flex gap-2 items-center">
                      <p className="font-bold text-md">
                        {location?.state?.item?.titre}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-auto mt-10 flex justify-center items-center p-5">
              <div className="bg-white p-4 rounded-xl pt-8 relative">
                <div className="absolute left-[50%] -top-6 translate-x-[-50%] bg-primary p-2 rounded-full text-white">
                  <Bulb color="#FFC807" size={30} />
                </div>
                <p className="text-primary font-bold text-center text-lg mb-1">
                  Recommandation
                </p>
                <span className="text-sm text-center">
                  {location?.state?.analyse?.recommandation ??
                    "Pas de recommandation"}
                </span>
              </div>
            </div>
          </>
        )}
      />
    </Template>
  );
}

export default ExerciceDetailPage;
