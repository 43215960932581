import React from "react";
import { useNavigate } from "react-router-dom";

function NombreEnfantPage() {
  const navigation = useNavigate();
  return (
    <div className="flex-1 bg-[#fff] h-screen py-10 px-5">
      <p className="text-primary font-bold text-lg">
        Vous voulez effectuer le diagnostic avec combien d'enfant ?
      </p>

      <p className="mt-10 text-xl font-bold">Entre :</p>
      <div className="grid grid-cols-3 gap-2 mt-5">
        {[
          { id: 1, label: "0 - 1", nombre: 1 },
          { id: 2, label: "1 - 3", nombre: 3 },
          { id: 3, label: "+3", nombre: "n" },
        ].map((item) => (
          <div
            key={item?.id}
            onClick={() =>
              navigation("../form", {
                replace: true,
                state: { nombre: item?.nombre },
              })
            }
            className="border-2 border-gray rounded-lg p-5 py-8 flex items-center justify-center text-xl font-bold hover:bg-[rgba(0,0,0,0.1)] cursor-pointer"
          >
            {item?.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NombreEnfantPage;
