import API from "routes/api.path";
import { ApiMananger } from "./apiMananger";

const parentApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getParent: build.query({
      query: (id) => `/parents/${id}/`,
      providesTags: ["Parents"],
    }),
    getDiagnostiqueWithNiveaux: build.query({
      query: (id) => API.DIAGNOSTICS_PARENT.GET_WITH_NIVEAUX(id),
      providesTags: ["Parents"],
    }),
    getParents: build.query({
      query: () => `/parents/`,
      providesTags: ["Parents"],
      provides: { data: true },
    }),
    addParent: build.mutation({
      query(body) {
        return {
          url: `/parents/`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    contactParent: build.mutation({
      query(body) {
        return {
          url: `/parents/contacts/`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    addProspectParent: build.mutation({
      query(body) {
        return {
          url: `/prospects/`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    updateParent: build.mutation({
      query(data) {
        const { parentId, ...body } = data;
        return {
          url: `parents/${parentId}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    updateParentStatus: build.mutation({
      query(data) {
        const { prospectId, ...body } = data;
        return {
          url: `/prospects/${prospectId}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
  }),
});

export const {
  useAddParentMutation,
  useAddProspectParentMutation,
  useContactParentMutation,
  useGetDiagnostiqueWithNiveauxQuery,
  useGetParentQuery,
  useGetParentsQuery,
  useUpdateParentMutation,
  useUpdateParentStatusMutation,
} = parentApi;
