import {API_URL} from "./api";

/**
 * +----------------------------------------------------------------------------+
 *      USER API                                                                |
 * +----------------------------------------------------------------------------+
 */
export const LOGIN_PATH = "users/login/"; //POST
export const AUTH_PATH = "users"; //POST
export const LOGOUT_PATH = "users/logout/"; //GET
export const REGISTER_PATH = "coachs/"; //POST
export const UPDATE_PASSWORD_PATH = "users/password/";

/**
 * +----------------------------------------------------------------------------+
 *      NUBER VERIFIER API                                                      |
 * +----------------------------------------------------------------------------+
 */
export const NUMBER_VERIFIER_PATH = API_URL + "verify/number/"; //GET + {number}

/**
 * +----------------------------------------------------------------------------+
 *      CSRF API                                                                |
 * +----------------------------------------------------------------------------+
 */
export const CSRF_TOKEN_PATH = API_URL + "users/csrf/";

/**
 * +----------------------------------------------------------------------------+
 *      STUDENTS API                                                            |
 * +----------------------------------------------------------------------------+
 */
export const STUDENTS_API = "eleves/coach"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      CALL STUDENTS API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const APPEL_API = "appels"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      FICHES API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const FICHE_API = "fiches"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      EVALUTIONS API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const EVALUATION_API = "evaluations"; //GET + {id}

const generateApiPath = (basePath) => {
  return {
    GET: (id) => `${basePath}/${id}/`,
    GETALL: () => `${basePath}/`,
    ADD: () => basePath + "/",
    EDIT: (id) => `${basePath}/${id}/`,
    DELETE: (id) => `${basePath}/${id}/`,
  };
};

const API = {
  USER_API: {
    LOGIN: "login/",
    REGISTER: "users/",
    GET_USER: "",
    LOGOUT: "",
  },
  COACHS_API: {
    ...generateApiPath("coachs"),
  },
  ELEVES_PARENT: {
    ...generateApiPath("students/parent"),
  },
  ELEVES_API: {
    ...generateApiPath("eleves"),
  },
  APPELS_API: {
    ...generateApiPath("appels"),
  },
  PARENTS_API: {
    ...generateApiPath("parents"),
  },
  MATIERES_API: {
    ...generateApiPath("matieres"),
  },
  ECOLES_API: {
    ...generateApiPath("schools"),
  },
  CLASSES_API: {
    ...generateApiPath("classes"),
  },
  VILLES_API: {
    ...generateApiPath("villes"),
  },
  NIVEAUX_API: {
    ...generateApiPath("niveaux"),
  },
  DIAGNOSTIUES_API: {
    ...generateApiPath("diagnostics"),
    GET_WITH_NIVEAUX: (id) => "diagnostics/niveaux/" + id + "/",
  },
  DIAGNOSTICS_PARENT: {
    ...generateApiPath("diagnostics/epreuves/parents"),
    GET_WITH_NIVEAUX: (id) => "epreuves/parents/diagnostic/" + id + "/",
  },
  DIAGNOSTICS_NIVEAU_API: {
    ...generateApiPath("diagnostics/niveaux"),
  },
  EPREUVES_API: {
    ...generateApiPath("epreuves"),
  },
};

export default API;
