import PageHeader from "components/PageHeader";
import { Template } from "layouts/Template";
import { useNavigate } from "react-router-dom";

function InfoBeforeDiagnosticPage() {
  const navigation = useNavigate();

  // useEffect(() => {
  //   console.log({ students });
  // }, []);

  return (
    <Template
      header={<PageHeader title={""} />}
      style={{
        backgroundColor: "#fff",
        padding: 10,
      }}
    >
      <div className="flex-1 overflow-auto">
        <p className="text-primary font-bold text-lg">
          Quelques information a savoir avant de commencer le diagnostic :
        </p>
        <ul className="mt-8 font-bold list-disc px-5 flex flex-col gap-5">
          <li>Le diagnostic va duree entre 15 et 20 minutes.</li>
          <li>
            Il est important de faire le diagnostic dans un androit calme.
          </li>
          <li>
            Ceci est une Demo qui a pour objectif de vous aidez a indentifier
            les lacunes de votre enfant.
          </li>
        </ul>

        <div className="px-0 my-8">
          <button
            className="bg-primary text-white disabled:opacity-40"
            style={{
              borderRadius: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              gap: 10,
              padding: "10px",
              // margin: "20px 10px",
              width: "100%",
            }}
            onClick={() => navigation("../select-enfant", { replace: true })}
          >
            Continuer
          </button>
        </div>
      </div>
    </Template>
  );
}

export default InfoBeforeDiagnosticPage;
