import axios from "axios";
import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { TTS_API_URL } from "routes/api";
const API_AUDIO = TTS_API_URL;

function AudioPage() {
  const [file, setFile] = useState(null);

  const handleSendFile = async () => {
    const formatData = new FormData();
    if (file) {
      formatData.append("upload_file", file, "audio.mp3");
      if (file) {
        const res = await axios.post(`${API_AUDIO}transcription`, formatData);

        // console.log({ res });
      }
    }
  };

  //   const addAudioElement = (blob) => {
  //     const url = URL.createObjectURL(blob);
  //     const audio = document.createElement("audio");

  //     audio.src = url;
  //     audio.controls = true;
  //     document.body.appendChild(audio);
  //   };

  // const addAudioElement = async (blob) => {
  //   const url = URL.createObjectURL(blob);
  //   const audio = document.createElement("audio");
  //   audio.src = url;
  //   audio.controls = true;
  //   document.body.appendChild(audio);

  //   // Créer un objet FormData et ajouter le blob
  //   const formData = new FormData();
  //   formData.append("upload_file", blob, "audio.webm");

  //   try {
  //     // Envoyer le fichier à l'API
  //     const res = await axios.post(`${API_AUDIO}transcription`, formData);
  //     console.log("Réponse de l'API:", res);
  //   } catch (error) {
  //     console.error("Erreur lors de l'envoi du fichier audio", error);
  //   }
  // };

  // Fonction pour ajouter un élément audio et uploader le blob
  const addAudioElement = async (blob) => {
    // Créer une URL pour le blob
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);

    // Créer un objet FormData et ajouter le blob
    const formData = new FormData();
    formData.append("upload_file", blob, "audio.mp3");

    try {
      // Envoyer le fichier à l'API
      const res = await axios.post(`${API_AUDIO}transcription`, formData);
      // console.log("Réponse de l'API:", res);
    } catch (error) {
      console.error("Erreur lors de l'envoi du fichier audio", error);
    }
  };

  return (
    <div>
      AudioPage
      <input
        type="file"
        name="audio"
        id="audio"
        onChange={(e) => {
          console.log(e.target.files);
          setFile(e.target?.files[0]);
        }}
      />
      <button onClick={handleSendFile}>envoyer</button>
      <AudioRecorder
        onRecordingComplete={addAudioElement}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        downloadOnSavePress={true}
        downloadFileExtension="mp3"
        showVisualizer
      />
    </div>
  );
}

export default AudioPage;
